module.exports = [{
      plugin: require('/var/www/html/site_generator/node_modules/gatsby-plugin-glamor/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/html/site_generator/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true,"trackingId":"UA-142338183-1"},
    },{
      plugin: require('/var/www/html/site_generator/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('/var/www/html/site_generator/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
