// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/var/www/html/site_generator/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-university-js": () => import("/var/www/html/site_generator/src/pages/university.js" /* webpackChunkName: "component---src-pages-university-js" */),
  "component---src-pages-services-js": () => import("/var/www/html/site_generator/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-contact-js": () => import("/var/www/html/site_generator/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-about-js": () => import("/var/www/html/site_generator/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-templates-blog-js": () => import("/var/www/html/site_generator/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-service-js": () => import("/var/www/html/site_generator/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-work-js": () => import("/var/www/html/site_generator/src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-templates-university-js": () => import("/var/www/html/site_generator/src/templates/university.js" /* webpackChunkName: "component---src-templates-university-js" */),
  "component---src-pages-404-js": () => import("/var/www/html/site_generator/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/var/www/html/site_generator/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/var/www/html/site_generator/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-university-admin-js": () => import("/var/www/html/site_generator/src/pages/university-admin.js" /* webpackChunkName: "component---src-pages-university-admin-js" */),
  "component---src-pages-work-js": () => import("/var/www/html/site_generator/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

